import ChatApp from '@shobbak/bulbul-chat-web';
import React from 'react';

import '@shobbak/bulbul-chat-web/dist/index.css';

const App = () => {
  return <ChatApp endpoint={process.env.REACT_APP_BULBUL_CHAT_ENDPOINT} />;
};

export default App;
